<template>
  <div class="product">
    <card title="基本信息">
      <ul class="shop-item seller-item" v-if="seller.name">
        <li v-if="seller.market_name">
          <p class="item-name">市场名称</p>
          <p class="item-value">{{ seller.market_name }}</p>
        </li>
        <li v-if="seller.name">
          <p class="item-name">经营户名称</p>
          <p class="item-value">{{ seller.name }}</p>
        </li>
        <li v-if="seller.customCode && seller.customCode != '0'">
          <p class="item-name">摊位号</p>
          <p class="item-value">{{ seller.customCode }}</p>
        </li>
        <li v-if="seller.tax">
          <p class="item-name">营业执照</p>
          <p class="item-value">{{ seller.tax }}</p>
        </li>
      </ul>
      <div class="null-item" v-else>无此商家信息</div>
    </card>
    <card title="进货信息" v-if="tracinngInfo">
      <div 
        v-for="item in tracinngInfo"
        :key="item.bno"
        class="tracing-itme">
        <h3 class="item-time" v-if='item.inDate'>进货时间：{{ item.inDate }}</h3>
        <h3 class="item-time" v-if='item.created_date'>进货时间：{{ item.created_date }}</h3>
        <ul class="shop-item">
          <li v-if="item.bno">
            <p class="item-name">追溯码</p>
            <p class="item-value">{{ item.bno }}</p>
          </li>
          <li v-if="item.batchNumber">
            <p class="item-name">批次号</p>
            <p class="item-value">{{ item.batchNumber }}</p>
          </li>
          <li class="long-name-item" v-if="item.quarantineAnimalProductsId">
            <p class="item-name">动物产品检疫合格证号</p>
            <p class="item-value">{{ item.quarantineAnimalProductsId }}</p>
          </li>
          <li class="long-name-item" v-if="item.quarantine_animal_products_id">
            <p class="item-name">动物产品检疫合格证号</p>
            <p class="item-value">{{ item.quarantine_animal_products_id }}</p>
          </li>
          <li class="long-name-item" v-if="item.inspectionMeatId">
            <p class="item-name">肉品品质检验合格证号</p>
            <p class="item-value">{{ item.inspectionMeatId }}</p>
          </li>
          <li class="long-name-item" v-if="item.inspection_meat_id">
            <p class="item-name">肉品品质检验合格证号</p>
            <p class="item-value">{{ item.inspection_meat_id }}</p>
          </li>
          <li class="long-name-item" v-if="item.quarantine_vege_id">
            <p class="item-name">蔬菜产品检验合格证号</p>
            <p class="item-value">{{ item.quarantine_vege_id }}</p>
          </li>
          <li class="long-name-item" v-if="item.quarantine_vege_id">
            <p class="item-name">蔬菜产品检验合格证号</p>
            <p class="item-value">{{ item.quarantine_vege_id }}</p>
          </li>
          <li v-if="item.truck_lic">
            <p class="item-name">车牌号</p>
            <p class="item-value">{{ item.truck_lic }}</p>
          </li>
          <li v-if="item.name">
            <p class="item-name">进场品种</p>
            <p class="item-value">{{ item.name }}</p>
          </li>
          <li v-if="item.product_name">
            <p class="item-name">进场品种</p>
            <p class="item-value">{{ item.product_name }}</p>
          </li>
          <li v-if="item.weight">
            <p class="item-name">进场重量</p>
            <p class="item-value">{{ item.weight }}千克</p>
          </li>
          <li v-if="item.quantity">
            <p class="item-name">进场重量</p>
            <p class="item-value">{{ item.quantity }}千克</p>
          </li>
          <li v-if="item.origin">
            <p class="item-name">产地</p>
            <p class="item-value">{{ item.origin }}</p>
          </li>
          <li v-if="item.product_origin">
            <p class="item-name">产地</p>
            <p class="item-value">{{ item.product_origin }}</p>
          </li>
          <li v-if="item.upstreamMarket">
            <p class="item-name">供货市场</p>
            <p class="item-value">{{ item.upstreamMarket }}</p>
          </li>
          <li v-if="item.market_name">
            <p class="item-name">供货市场</p>
            <p class="item-value">{{ item.market_name }}</p>
          </li>
          <li v-if="item.upstreamSeller">
            <p class="item-name">供货商</p>
            <p class="item-value">{{ item.upstreamSeller }}</p>
          </li>
          <li v-if="item.check">
            <p class="item-name">检验结果</p>
            <p class="item-value">{{ item.check }}</p>
          </li>
          <li v-if="item.check_result">
            <p class="item-name">检验结果</p>
            <p class="item-value">{{ item.check_result }}</p>
          </li>
        </ul>
      </div>
    </card>
  </div>
</template>

<script>
import Card from './components/Card.vue'
export default {
  name: '',
  components: {
    Card
  },
  data () {
    return {
      seller: {},
      tracinngInfo: []
    }
  },
  mounted () {
    let productId = this.$route.query.productId
    let profileId = this.$route.query.profileId
    let isOrderTrace = this.$route.query.isOrderTrace

    if (productId && profileId) {
      this.getSeller(profileId)
      if (isOrderTrace) {
        this.getOrderTrackingInfo(productId, profileId)
      } else {
        this.getTrackingInfo(productId, profileId)
      }
    }
  },
  methods: {
    getSeller (profileId) {
      this.$api.orderTracking.findOne({
        shopId: profileId
      }).then(res => {
        if (res.data.success) {
          this.seller = res.data.result
        }
      })
    },
    getTrackingInfo (productId, profileId) {
      this.$api.orderTracking.handmadeTraceDetails({
        productId: productId,
        profileId: profileId
      }).then(res => {
        if (res.data.success) {
          this.tracinngInfo = res.data.result
        }
      })
    },
    getOrderTrackingInfo (productId, profileId) {
      this.$api.orderTracking.orderTraceDetails({
        product_id: productId,
        profile_id: profileId
      }).then(res => {
        if (res.data.success) {
          res.data.result.batchNumber = res.data.result.bno
          res.data.result.bno = ''
          this.tracinngInfo = [res.data.result]
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.product{
  margin-top: .3rem;
}
.st-item{
  margin: 0 .3rem .3rem;
  padding: 0;
}
.shop-item {
  li {
    line-height: .74rem;
    padding: 0 !important;
    overflow: hidden;
    .item-name,
    .item-value {
      width: 30%;
      text-align: left;
      box-sizing: border-box;
      float: left;
    }
    .item-value {
      width: 70%;
      text-align: right;
    }
  }
  .long-name-item {
    .item-name,
    .item-value {
      width: 50%;
    }
  }
}
.seller-item {
  position: relative;
  &:after {
    content: '';
    width: 1.73rem;
    height: 1.73rem;
    background: url("https://smartracing.oss-cn-hangzhou.aliyuncs.com/assets/images/smartracing-pay/testing-icon-1.png") center center no-repeat;
    background-size: 100% auto;
    position: absolute;
    right: -.4rem;
    top: -1.2rem;
  }
}
.null-item {
  padding: 1rem;
  text-align: center;
}
.tracing-itme {
  padding-left: .2rem;
  position: relative;
  &:after,
  &:before {
    content: '';
    position: absolute;
  }
  // &:after {
  //   width: 1px;
  //   height: 100%;
  //   background: #ccc;
  //   left: .17rem;
  //   top: .2rem;
  // }
  &:before {
    width: 2.6rem;
    height: 2.6rem;
    background: url("https://smartracing.oss-cn-hangzhou.aliyuncs.com/assets/images/smartracing-pay/testing-item-bg.png") center center no-repeat;
    background-size: 100%;
    right: .2rem;
    top: 1rem;
  }
  &:nth-last-of-type(1) {
    &:after {
      display: none;
    }
  }
  .item-time {
    font-size: .28rem;
    color: #000;
    line-height: .4rem;
    padding: .16rem 0 .32rem;
    position: relative;
    &:after {
      content: '';
      width: .28rem;
      height: .28rem;
      background: url("https://smartracing.oss-cn-hangzhou.aliyuncs.com/assets/images/smartracing-pay/icon/time-mini.png") center center no-repeat;
      background-size: 100%;
      margin-top: -.25rem;
      position: absolute;
      left: -.35rem;
      top: 52%;
      z-index: 9;
    }
  }
  ul {
    padding-bottom: .32rem;
    li {
      padding: 0 !important;
    }
  }
}
</style>
